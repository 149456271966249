<script>
import ScoreFunctions from '@/mixins/scoreFunctions.js'

export default {
  name: 'ExerciceDetail',
  mixins: [ScoreFunctions],
  props: {
    // exercice: { required: false, type: Object }
    exercice: { required: false, type: Object }
    // type: { required: true, type: String }
  },
  data () {
    return {
      showHisto: false
    }
  },
  computed: {
    statut () {
      let temp = null
      if (this.exercices) {
        this.exercices.forEach(element => {
          if (element.status === 'in_process' && temp !== 'finished') {
            temp = 'in_process'
          } else if (element.status === 'finished') {
            temp = 'finished'
          }
        })
      }
      return temp
    },
    score () {
      let temp = 0
      if (this.exercices) {
        this.exercices.forEach(element => {
          if (element.status === 'finished' && element.mark > temp) {
            temp = element.mark
          }
        })
      }
      return temp
    }
  },
  methods: {
    formatDate (date) {
      const temp = date.split(' ')
      const dateFormat = temp[0] + 'T' + temp[1] + temp[2]
      return new Date(dateFormat).toLocaleString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })
    }
  }
}
</script>
<template lang="pug">
  .exercice-detail.w-100
    v-list-item-content
      v-list-item-title.f5.b.mb2 {{exercice.titre}}
      //- v-list.grey.lighten-2.mt2(dense, style='padding: 0 !important;')
        v-list-item(:key='index', style='min-height: 30px !important;')
          v-list-item-content
            div.flex.justify-between.items-center.f6
              div {{formatDate(exercice.updated_at)}}
              div.flex-grow-1
              div(v-if='exercice.graph.moyenne', style='display: flex; align-items: center')
                span Moyenne:
                div.ml1.b {{parseInt(exercice.graph.moyenne.toFixed(2))}}%
                v-avatar.ml2(size='10', :color='getColorFromResult(exercice.graph.moyenne.toFixed(2))')
              div(v-if='exercice.mark', style='display: flex; align-items: center; margin-left: 10px; padding-left: 10px; border-left: solid 1px black;')
                span Résultat:
                div.ml1.b {{parseInt(exercice.mark.toFixed(2))}}%
                v-avatar.ml2(size='10', :color='getColorFromResult(exercice.mark.toFixed(2))')
              div(v-else, style='display: flex; align-items: center; margin-left: 10px; padding-left: 10px; border-left: solid 1px black;') Non terminé
          //- v-divider(v-if='index < (exercices.length - 1)')
</template>
