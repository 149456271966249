<script>
import ChatControls from '@/mixins/chatControls.js'
// import EnfantCard from '@/components/presentation/enfantCard.vue'
import ExerciceDetail from '@/components/enfants/ExerciceDetail'
// import Resultats from '@/components/eleves/resultats'
import { groupBy } from 'lodash-es'
// import axios from 'axios'

export default {
  name: 'devoirDetail',
  mixins: [ChatControls],
  components: {
    // EnfantCard
    ExerciceDetail
    // Resultats
  },
  computed: {
    score () {
      let temp = 0
      if (this.activeDevoir) {
        this.activeDevoir.exercices.forEach(element => {
          if (element.status === 'inactif' && element.mark > temp) {
            temp = element.mark
          }
        })
      }
      return temp
    },
    activeDevoir () {
      if (!this.skipQuery && this.listDevoir.length > 0) {
        const temp = []
        const groupement = groupBy(this.listDevoir, 'batch')
        Object.keys(groupement).forEach((group) => {
          console.log('group', group)
          const listDevoir = []
          let donnateur = null
          let debutAt = null
          let finAt = null
          let titre = null
          let description = null
          let status = null
          const batch = group
          let nombreUsagers = 0
          let mark = 0
          groupement[group].forEach((elem) => {
            titre = elem.titre
            description = elem.description
            if (nombreUsagers === 0) {
              nombreUsagers = elem.elementsUsagers.total
            }
            if (!status) {
              status = elem.statut
            }
            if (!donnateur) {
              donnateur = elem.usager
            }
            if (!debutAt && elem.debutAt) {
              debutAt = elem.debutAt
            }
            if (!finAt && elem.finAt) {
              finAt = elem.finAt
            }
            if (elem.mark) {
              mark += elem.mark
            }
            // meta contient pas mal de stock genre couleur et tout
            let type = elem.meta.includes('SuperNoeud') ? 'supernoeud' : 'graph'
            let devoirId = null
            const valeur = JSON.parse(elem.meta)
            if (type === 'supernoeud') {
              devoirId = valeur.split('gid://v1/Graphs::Graph::SuperNoeud/')[1]
            } else {
              devoirId = valeur.split('gid://v1/Graphs::Graph::Super/')[1]
            }
            listDevoir.push({ type, devoir: devoirId, statut: elem.statut })
          })
          temp.push({ batch, titre, description, from: donnateur, debutAt, finAt, exercices: listDevoir, mark, status, nombreUsagers })
        })
        return temp[0]
      } else {
        return this.$store.state.App.devoirActif
      }
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          console.log('NEWVAL', newVal)
          this.checkIfDevoirPresent()
        }
      }
    }
  },
  data () {
    return {
      user: null,
      dokomaEx: null,
      skupQuery: true,
      showDialogLoading: false,
      devoir: null,
      pageDevoir: 1,
      listDevoir: []
    }
  },
  apollo: {
    devoirByQuery: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElementsUsagers.gql'),
      variables () {
        return {
          filtre: {
            usagers: [this.$route.params.id],
            batches: [this.$route.params.idDevoir]
          }
        }
      },
      update (data) {
        console.log('LE DATA', data)
        if (data.calendrierCalendrierElementsUsagers && data.calendrierCalendrierElementsUsagers.total) {
          this.devoirsTotal = data.calendrierCalendrierElementsUsagers.total
          this.listDevoir = data.calendrierCalendrierElementsUsagers.resultat
          if (this.pageDevoir === 1) {
            this.$nextTick(() => {
              this.fetchMoreDevoirs()
            })
          }
        }
        return data.calendrierCalendrierElementsUsagers
      },
      skip () {
        return this.skipQuery
      }
    }
  },
  methods: {
    async fetchMoreDevoirs () {
      if (this.devoirsTotal > this.listDevoir.length) {
        console.log('page', this.pageDevoir)
        this.pageDevoir++
        try {
          await this.$apollo.queries.devoirByQuery.fetchMore({
            variables: {
              page: this.pageDevoir
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              this.listDevoir.push(...fetchMoreResult.calendrierCalendrierElementsUsagers.resultat)
              return {
                calendrierCalendrierElementsUsagers: {
                  __typename: previousResult.calendrierCalendrierElementsUsagers.__typename,
                  resultat: [...previousResult.calendrierCalendrierElementsUsagers.resultat, ...fetchMoreResult.calendrierCalendrierElementsUsagers.resultat],
                  total: previousResult.calendrierCalendrierElementsUsagers.total
                }
              }
            }
          })
        } catch (e) {
          console.error(e)
        }
        this.fetchMoreDevoirs()
      }
    },
    checkIfDevoirPresent () {
      if (!this.$store.state.App.devoirActif || this.$store.state.App.devoirActif.batch !== this.$route.params.idDevoir) {
        this.skipQuery = false
      } else {
        this.devoir = this.$store.state.App.devoirActif
      }
    }
  }
}
</script>

<template lang='pug'>
  .devoirs-index.split-page(v-if='activeDevoir')
    .page-header(color='primary')
      .header__inner-content
        .header-title()
          div.main-title {{activeDevoir.titre}}
    .page__main-content
      div {{activeDevoir.description}}
      div Donné par {{activeDevoir.from.info.nom}} {{activeDevoir.from.info.prenom}}
      div(v-if='activeDevoir.finAt') Date de rendu {{new Date(activeDevoir.finAt).toLocaleDateString('fr-FR')}}
      div Statut {{activeDevoir.statut}}
      div(v-if='activeDevoir.mark > 0') Note {{activeDevoir.mark}}
      div {{activeDevoir.exercices.length}} Exercices
      div
        exercice-detail(v-for='(exercice, index) in activeDevoir.exercices', :key='index', :exercice='exercice')
    //- Transférer le contenu de HardcoddedDevoir quand les devoirs seront disponibles
</template>
<style lang='sass'>
  @import 'src/styles/pages/_devoirDetail'
</style>
